import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import HomeContent from 'views/home/HomeContent';

// dashboard routing
const DashboardFiles = Loadable(lazy(() => import('views/dashboard/File')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomeContent />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'files',
          element: <DashboardFiles />
        }
      ]
    }
  ]
};

export default MainRoutes;
