import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ProgramRoutes from './ProgramRoutes';
import PartnerRoutes from './PartnerRoutes';
import DashboardMapRoutes from './DashboardMapRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, DashboardMapRoutes, AuthenticationRoutes, ProgramRoutes, PartnerRoutes]);
}
