// import dashboard from './dashboard';
import pages from './pages';
import programs from './programs';
import partners from './partners';

// Function to check if access token is present in local storage
const isTokenAvailable = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken !== null;
};

// Construct menu items based on token availability
const getMenuItems = () => {
  if (isTokenAvailable()) {
    if (programs.children.length !== 0) {
      if (partners.children.length !== 0) {
        return {
          items: [programs, partners]
        };
      } else {
          return {
            items: [programs]
          }
      }
    } else {
      return {
        items: [partners]
      };
    }
  } else {
    return {
      items: [pages]
    };
  }
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = getMenuItems();

export default menuItems;
