import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import homeImage from '../../assets/images/home-image.jpg';

const HomeContent = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }

        setTimeout(() => {
            setIsContentVisible(true);
        }, 200);

    }, []);

    return (
        <Paper 
            elevation={3} 
            sx={{
                position: 'relative',
                padding: theme.spacing(3),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                overflow: 'hidden',
                opacity: isContentVisible ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',

            }}
        >
            {/* Background image with blur */}
            <div 
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${homeImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(10px)',
                    zIndex: 1,
                }}
            ></div>

            {/* Whitish hue overlay */}
            <div 
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    zIndex: 2,
                }}
            ></div>

            {/* Content that should not be blurred */}
            <div style={{ position: 'relative', zIndex: 3 }}> {/* Ensure content is above the overlay */}
                <Typography variant="h1" gutterBottom>
                    Justice & Community Safety Initiative
                </Typography>
                <br />

                {/* Different content for logged in and logged out users */}
                {isAuthenticated ? (
                    <>
                        <Typography variant="h3" gutterBottom>
                            Welcome Back to Your Dashboard
                        </Typography>
                        <Typography variant="body1">
                            You are logged in, feel free to access your programs and its resources.
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h3" gutterBottom>
                            Welcome to the Dashboard
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Please log in to access your programs and its resources.
                        </Typography>
                    </>
                )}

                {/* Buttons */}
                <Grid container spacing={2} justifyContent="center" mt={3}>
                    <Grid item>
                        <Button
                            variant="contained"
                            href="https://public.kcjusticeinitiative.org/"
                            target="_blank"
                        >
                            Go to Public Site
                        </Button>
                    </Grid>

                    {!isAuthenticated && (
                        <Grid item>
                            <Button variant="contained" onClick={() => navigate('/pages/login/login3')}>
                                Log In
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Paper>
    );
};

export default HomeContent;
