import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
//const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardMap = Loadable(lazy(() => import('views/dashboard/Map')));
const DashboardFiles = Loadable(lazy(() => import('views/dashboard/File')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardMap />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'map',
          element: <DashboardMap />
        },
        {
          path: 'files',
          element: <DashboardFiles/>
        }
      ]
    }
  ]
};

export default MainRoutes;
