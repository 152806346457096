import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  // Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconUser, IconMap, IconHome } from '@tabler/icons';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // state to track login status

  useEffect(() => {
    // Check if token exists in local storage
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true); // Set login status to true if token exists
    } else {
      setIsLoggedIn(false); // Set login status to false if token doesn't exist
    }
  }, []);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log('Logout');
    localStorage.removeItem('accessToken');
    navigate('/');
    window.location.reload();  
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '40px',
          alignItems: 'center',
          borderRadius: '24px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            },
            '& .MuiChip-label, & .MuiTypography-root': {
              color: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          }
        }}
        // icon={
        //   <Avatar
        //     src={User1}
        //     sx={{
        //       ...theme.typography.mediumAvatar,
        //       margin: '8px 0 8px 8px !important',
        //       cursor: 'pointer'
        //     }}
        //     ref={anchorRef}
        //     aria-controls={open ? 'menu-list-grow' : undefined}
        //     aria-haspopup="true"
        //     color="inherit"
        //   />
        // }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconUser stroke={1.75} size="1.5rem" color={theme.palette.primary.main} />
            <Typography variant="body2" sx={{ marginLeft: '8px'}}>Account</Typography>
          </Box>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {isLoggedIn && ( // Conditionally render logout button if user is logged in
                          <>
                            
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick = {() => navigate('/')}
                            >
                              <ListItemIcon>
                                <IconHome stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">Home</Typography>} />
                              </ListItemButton>
                            
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick = {() => navigate('/dashboard/map')}
                            >
                              <ListItemIcon>
                                <IconMap stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">Map</Typography>} />
                            </ListItemButton>
                            
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick={handleLogout}
                            >
                              <ListItemIcon>
                                <IconLogout stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                              </ListItemButton>
                            </>
                        )}
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
