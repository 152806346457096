import axios from 'axios';

const defaultPrograms = {
  id: 'programs',
  title: 'Programs',
  type: 'group',
  children: [
    {
      id: 'secondChance',
      title: 'Second Chance',
      type: 'collapse',
      children: [
        {
          id: 'dataTable',
          title: 'Data Table',
          type: 'item',
          url: '/program/secondChance/dataTable',
          breadcrumbs: false,
        },
        {
          id: 'entryForm',
          title: 'Entry Form',
          type: 'item',
          url: '/program/secondChance/entryForm',
          breadcrumbs: false,
        },
        {
          id: 'fileUpload',
          title: 'File Upload',
          type: 'item',
          url: '/program/secondChance/fileUpload',
          breadcrumbs: false,
        }
      ]
    },
    {
      id: 'capitalCommunityFund',
      title: 'Capital Community Fund',
      type: 'collapse',
      children: [
        {
          id: 'blueValley',
          title: 'Blue Valley',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueValley/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/blueValley/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'blueHills',
          title: 'Blue Hills',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/blueHills/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/blueHills/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'bostonHeightsAndMountHope',
          title: 'Boston Heights and Mount Hope',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/bostonHeightsAndMountHope/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'columbusParkCommunityCouncil',
          title: 'Columbus Park Community Council',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/columbusParkCommunityCouncil/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'east23rdStPac',
          title: 'East 23rd St. Pac',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/east23rdStPac/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/east23rdStPac/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'dunbar',
          title: 'Dunbar',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/dunbar/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/dunbar/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'lykins',
          title: 'Lykins',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/lykins/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/lykins/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'marlboroughCommunity',
          title: 'Marlborough Community',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/marlboroughCommunity/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/marlboroughCommunity/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'pendletonHeightsNA',
          title: 'Pendleton Heights NA',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/pendletonHeightsNA/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/pendletonHeightsNA/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'santaFeAreaCouncil',
          title: 'Santa Fe Area Council',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/santaFeAreaCouncil/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/santaFeAreaCouncil/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'paseoWest',
          title: 'Paseo West',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/paseoWest/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/paseoWest/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'townForkCreek',
          title: 'Town Fork Creek',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/townForkCreek/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/townForkCreek/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'viewHighDriveNorth',
          title: 'View High Drive North',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/viewHighDriveNorth/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/viewHighDriveNorth/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'vineyard',
          title: 'Vineyard',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/vineyard/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/vineyard/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'wardParkwayHomesAssociation',
          title: 'Ward Parkway Homes Association',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/wardParkwayHomesAssociation/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'washingtonWheatley',
          title: 'Washington Wheatley',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/washingtonWheatley/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/washingtonWheatley/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'foxtownEast',
          title: 'Foxtown East',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/foxtownEast/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/foxtownEast/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'triBlenheim',
          title: 'Tri Blenheim',
          type: 'collapse',
          children: [
            {
              id: 'leader',
              title: 'Leader',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/leader/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/leader/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'event',
              title: 'Event',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/event/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/event/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'citizen',
              title: 'Citizen',
              type: 'collapse',
              children: [
                {
                  id: 'dataTable',
                  title: 'Data Table',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/citizen/dataTable',
                  breadcrumbs: false,
                },
                {
                  id: 'entryForm',
                  title: 'Entry Form',
                  type: 'item',
                  url: '/program/capitalCommunityFund/triBlenheim/citizen/entryForm',
                  breadcrumbs: false,
                },
              ]
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/capitalCommunityFund/triBlenheim/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
      ]
    },
    {
      id: 'KCCommonGood',
      title: 'KC Common Good',
      type: 'collapse',
      children: [
        {
          id: 'girlScouts',
          title: 'Girl Scouts',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/girlScouts/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/girlScouts/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/girlScouts/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'centerForConflictResolution',
          title: 'Center For Conflict Resolution',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/centerForConflictResolution/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/centerForConflictResolution/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/centerForConflictResolution/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'fullEmploymentCouncil',
          title: 'Full Employment Council',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/fullEmploymentCouncil/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/fullEmploymentCouncil/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/fullEmploymentCouncil/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'gratefulEducationFoundation',
          title: 'Grateful Education Foundation',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/gratefulEducationFoundation/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/gratefulEducationFoundation/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/gratefulEducationFoundation/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'KCFreedomSchools',
          title: 'KC Freedom Schools',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/KCFreedomSchools/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/KCFreedomSchools/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/KCFreedomSchools/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'lyriksInstitution',
          title: 'Lyriks Institution',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/lyriksInstitution/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/lyriksInstitution/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/lyriksInstitution/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'jamisonCMETemple',
          title: 'Jamison CME Temple',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/jamisonCMETemple/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/jamisonCMETemple/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/jamisonCMETemple/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'theHopeCenter',
          title: 'The Hope Center',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/theHopeCenter/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/theHopeCenter/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/theHopeCenter/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'urbanRangersCorp',
          title: 'Urban Rangers Corp',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/urbanRangersCorp/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/urbanRangersCorp/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/urbanRangersCorp/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'youthAmbassadors',
          title: 'Youth Ambassadors',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/youthAmbassadors/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/youthAmbassadors/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/youthAmbassadors/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'youthGuidance',
          title: 'Youth Guidance',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/KCCommonGood/youthGuidance/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/KCCommonGood/youthGuidance/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/KCCommonGood/youthGuidance/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
      ]
    },
    {
      id: 'iBuild',
      title: 'IBuild',
      type: 'collapse',
      children: [
        {
          id: 'dataTable',
          title: 'Data Table',
          type: 'item',
          url: '/program/iBuild/dataTable',
          breadcrumbs: false,
        },
        {
          id: 'entryForm',
          title: 'Entry Form',
          type: 'item',
          url: '/program/iBuild/entryForm',
          breadcrumbs: false,
        },
        {
          id: 'fileUpload',
          title: 'File Upload',
          type: 'item',
          url: '/program/iBuild/fileUpload',
          breadcrumbs: false,
        }
      ]
    },
    {
      id: 'mentalHealth',
      title: 'Mental Health',
      type: 'collapse',
      children: [
        {
          id: 'journeytoNewLife',
          title: 'Journey to New Life',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLife/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLife/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLife/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'piecesPeaces',
          title: 'Pieces Peaces',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/piecesPeaces/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/piecesPeaces/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/piecesPeaces/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'totalManCDC',
          title: 'Total Man CDC',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/totalManCDC/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/totalManCDC/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/totalManCDC/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'CCON(Calvary)',
          title: 'CCON (Calvary)',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/CCON(Calvary)/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/CCON(Calvary)/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/CCON(Calvary)/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'journeytoNewLifeCase',
          title: 'Journey to New Life Case',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLifeCase/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLifeCase/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/journeytoNewLifeCase/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'lyriksInstitutionCase',
          title: 'Lyriks Institution Case',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/lyriksInstitutionCase/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/lyriksInstitutionCase/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/lyriksInstitutionCase/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'KCPublicLibraryPatron',
          title: 'KC Public Library Patron',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryPatron/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryPatron/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryPatron/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'KCPublicLibraryStaff',
          title: 'KC Public Library Staff',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryStaff/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryStaff/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/KCPublicLibraryStaff/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'campChoiceAHSaturday',
          title: 'Camp Choice AH Saturday',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/campChoiceAHSaturday/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/campChoiceAHSaturday/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/campChoiceAHSaturday/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
        {
          id: 'campChoiceRetreatAndRevival',
          title: 'Camp Choice Retreat and Revival',
          type: 'collapse',
          children: [
            {
              id: 'dataTable',
              title: 'Data Table',
              type: 'item',
              url: '/program/mentalHealth/campChoiceRetreatAndRevival/dataTable',
              breadcrumbs: false,
            },
            {
              id: 'entryForm',
              title: 'Entry Form',
              type: 'item',
              url: '/program/mentalHealth/campChoiceRetreatAndRevival/entryForm',
              breadcrumbs: false,
            },
            {
              id: 'fileUpload',
              title: 'File Upload',
              type: 'item',
              url: '/program/mentalHealth/campChoiceRetreatAndRevival/fileUpload',
              breadcrumbs: false,
            }
          ]
        },
      ]
    },
  ]
};

const isTokenAvailable = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken !== null;
};

const fetchData = async () => {
  try {
    if (!isTokenAvailable()) {
      console.log('Token not available, returning default programs.');
      return defaultPrograms.children;
    }

    const jwtToken = localStorage.getItem('accessToken');
    const host = process.env.REACT_APP_API_HOST || 'localhost';
    const port = process.env.REACT_APP_API_PORT || '4747';
    const protocol = process.env.REACT_APP_API_PROTOCOL|| 'http';
    const response = await axios.get(`${protocol}://${host}:${port}/api/get_users_programs`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken ? `Bearer ${jwtToken}` : '',
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching user programs:', error);
    return defaultPrograms.children;
  }
};

const apiResponse = await fetchData();
console.log(apiResponse);

// Filter and update the programs only if the token is available
let updatedPrograms = defaultPrograms.children;
let filteredChildren = defaultPrograms.children;
if (isTokenAvailable()) {
  updatedPrograms = defaultPrograms.children.filter(item => {
    const isMatch = apiResponse.some(apiProgram => {
      if (apiProgram.partner === false) {
        const apiProgramName = apiProgram.program.toLowerCase();
        const itemTitle = item.title.toLowerCase();
        console.log(apiProgramName,itemTitle);
        return apiProgramName === itemTitle;
      }
      return false;
    });

    if (item.children) {
      const children = item.children.filter(child =>
        apiResponse.some(apiProgram =>
          child.title.toLowerCase() === apiProgram.program.toLowerCase()
        )
      )
      console.log("filtered child: ",children);

      if (children.length > 0){
         return true;
      }
    }

    return isMatch;
  });

  
  console.log("programs:", updatedPrograms);

  // Filter children of defaultPrograms.children based on API response
  filteredChildren = updatedPrograms.map(parent => ({
    ...parent,
    children: parent.children.filter(child =>
      child.type === 'item' ||
      apiResponse.some(apiProgram =>
        child.title.toLowerCase() === apiProgram.program.toLowerCase()
      )
    )
  }));

  console.log("filtered:",filteredChildren);
}

// Update the programs object with the dynamically updated programs
const programs = {
  ...defaultPrograms,
  // children: updatedPrograms,
  children: filteredChildren,
};

export default programs;