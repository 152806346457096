const pages = {
  id: 'pages',
  title: 'Auth',
  type: 'group',
  children: [
    {
      id: 'login3',
      title: 'Login',
      type: 'item',
      url: '/pages/login/login3',
    }
  ]
};

export default pages;
